import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import './login.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { playGame, loginUser, selectTempGameId } from '../store/screenSlice';


library.add(faPhone);


const Login = () => {
    const [loginDisabled, setLoginDisabled] = useState(false);
    const dispatch = useDispatch();
    const [linkHref, setLinkHref] = useState('');
    const [loading, setLoading] = useState(false);
    const id = useSelector(selectTempGameId); 
    const [msisdnValue, setMsisdnValue] = useState('');
   
    const resetMsisdn = () => {
        setMsisdnValue('');
    }

    const handleChangeMsisdn = (event) => {
        setMsisdnValue(event.target.value);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const getMsisdn = params.get('msisdn');
    
        if (getMsisdn) {
          const msisdnElement = document.getElementById('msisdn') as HTMLInputElement;
          msisdnElement.value = getMsisdn;
          handleLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = () => {
        setLinkHref('');
        setLoginDisabled(true);
        setLoading(true);
        const error_message = document.getElementById('error_message') as HTMLElement;
        const msisdnElement = document.getElementById('msisdn') as HTMLInputElement;
        const msisdn = msisdnElement ? msisdnElement.value : '';
        const validNumbers = /^[0-9]+$/;
        
        if(!msisdn) {
            msisdnElement.focus();
            error_message.textContent='Εισαγάγετε τον αριθμό τηλεφώνου σας';
            setLoginDisabled(false);
            setLoading(false);
            return false;
        }
        
        if(msisdn.length<6 || !msisdn.match(validNumbers)) {
            msisdnElement.focus();
            error_message.textContent='Ο αριθμός τηλεφώνου δεν φαίνεται έγκυρος.';
            setLoginDisabled(false);
            setLoading(false);
            return false;
        }


        const data = {
            msisdn: msisdn,
            client: '',
            country: ''
        }

        error_message.textContent='\u00A0';

        axios.post(`${process.env.REACT_APP_API_URL}?call=login`, data)
        .then(response => {
            if(response.data.result===1) { //success
                dispatch(loginUser());
                dispatch(playGame(id));
            } else { //no valido
                if (error_message) {
                    error_message.textContent = 'Ο αριθμός τηλεφώνου δεν έχει εγγραφεί.';
                }
                if(response.data.redirect) {
                    setLinkHref(response.data.redirect);
                }
                setLoginDisabled(false);
                setLoading(false);
            }
        })
        .catch(error => {
            console.error(error);
            setLoginDisabled(false);
            setLoading(false);
        });
    }

    return (
        <>
            <div id="containerLogin">
                <div className="d-flex justify-content-center h-100" id="innerContainer">
                    <div className="card">
                        <div className="card-header" id="header">
                            <h3>Gameswiper</h3>
                        </div>
                        <div className="card-body" id="formDiv">
                            <form id="form">
                                <div className="input-group form-group" id="fields">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text" id="phoneIcon"><FontAwesomeIcon icon="phone"/></span>
                                    </div>
                                    <input type="text" className="form-control" id="msisdn" value={msisdnValue} placeholder="Τηλέφωνο" onClick={resetMsisdn} onChange={handleChangeMsisdn} />
                                    
                                </div>
                                <div className="form-group" id="logindiv">
                                    <input type="button" value="Σύνδεση" id="login" className="btn login_btn" onClick={handleLogin} disabled={loginDisabled} />
                                </div>
                            </form>
                        </div>
                        <div id="container_error">
                            <div id="error_message">&nbsp;</div>
                            <div id="success_message"><img src={process.env.PUBLIC_URL + '/images/loading.gif'} className="loading" alt="loading" style={{ display: loading ? 'block' : 'none' }} /></div>
                        </div>
                        <div className="card-footer" id="divLink" style={{ display: linkHref ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center links">
                                Δεν έχετε ακόμα λογαριασμό;<a href={linkHref} id="landing">Εγγραφείτε</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login